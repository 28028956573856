import React, { useState, useEffect } from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from '@material-ui/core';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';

import { getTimeZones } from "@vvo/tzdb";

dayjs.extend(customParseFormat);
dayjs.extend(utc);

const supportedDateFormats = [
  'YYYY-MM-DD HH:mm:ss',
  'YYYY/MM/DD HH:mm:ss',
  'YYYY-MM-DD HH:mm',
  'MM-DD-YYYY HH:mm:ss',
  'MM-DD-YYYY hh:mm:ss A',
  'DD-MM-YYYY HH:mm:ss',
  'DD-MM-YYYY hh:mm:ss A',
  'MM/DD/YYYY HH:mm:ss',
  'MM/DD/YYYY hh:mm:ss A',
  'DD/MM/YYYY HH:mm:ss',
  'DD/MM/YYYY hh:mm:ss A',
  'DD/MM/YYYY HH:mm',
  'MM/DD/YYYY HH:mm',
  'M/D/YYYY H:mm',
  'M/D/YYYY HH:mm',
  'M/D/YYYY hh:mm A',
  'D/M/YYYY HH:mm',
  'D/M/YYYY hh:mm A',
  'MM/DD/YY HH:mm:ss',
  'MM/DD/YY hh:mm:ss A',
  'DD/MM/YY HH:mm:ss',
  'DD/MM/YY hh:mm:ss A',
  'HH:mm:ss  DD/MMM/YYYY', // hanwell hanlog32
];

const DEFAULT_DATE_FORMAT = supportedDateFormats[0];

const availableRegions = [
  'UTC',
  'America',
  'Europe',
  'Australia',
  'South America',
  'North America',
  'Pacific'
];
const timezones = getTimeZones({ includeUtc: true })
  .filter(tz => availableRegions.some(region => tz.name.includes(region)));

const temperatureUnits = [
  { value: 'celsius', label: 'Celsius (°C)' },
  { value: 'fahrenheit', label: 'Fahrenheit (°F)' }
];

const illuminanceUnits = [
  { value: 'lux', label: 'Lux' },
  { value: 'footcandle', label: 'Footcandle' }
];

const getUtcDescription = (tz) => {
  const offset = tz.rawOffsetInMinutes;
  const sign = offset < 0 ? '-' : '+';
  const minutes = Math.abs(offset / 60);
  return `UTC${sign}${minutes.toString()}`;
};

const getUtcLabel = (timezone) => {
  const tz = timezones.find(tz => tz.name === timezone);
  return `(${getUtcDescription(tz)})`;
};


const getTimezoneLabel = (tz) => {
  if (tz.name === 'Etc/UTC') {
    return 'UTC (Coordinated Universal Time)';
  }

  return `${tz.name} [${tz.abbreviation}] (${getUtcDescription(tz)})`;
};

const getInterpretedDate = (parsedDate, timezone) => {
  if (!parsedDate) return 'Invalid date format for sample date. Please select a different one.';
  return `${parsedDate} ${getUtcLabel(timezone)}`;
};

const getValidFormatsForDate = (datetime) => {
  const isAmPm = datetime.includes('AM') || datetime.includes('PM');
  const hasDash = datetime.includes('-');

  const firstFilter = supportedDateFormats.filter(format => {
    const parsed = dayjs(datetime, format);
    return parsed.isValid();
  });

  const secondFilter = firstFilter.filter(format => {
    return isAmPm ? format.includes('A') : !format.includes('A');
  });

  const thirdFilter = secondFilter.filter(format => {
    return hasDash ? format.includes('-') : !format.includes('-');
  });

  return thirdFilter;
};

function DataFormattingStep({ importConfig, onUpdateImportConfig, onError }) {
  let defaultDateFormat = importConfig.uploadFiles[0]?.parserConfig?.metadata?.dateformat;

  if (!defaultDateFormat) {
    defaultDateFormat = DEFAULT_DATE_FORMAT;
  }

  const [dateFormat, setDateFormat] = useState(defaultDateFormat);
  const [timezone, setTimezone] = useState('Etc/UTC');
  const [tempUnit, setTempUnit] = useState(temperatureUnits[0].value);
  const [illumUnit, setIllumUnit] = useState(illuminanceUnits[0].value);
  const [sampleData, setSampleData] = useState({
    datetime: '',
    temperature: '',
    illuminance: ''
  });
  const [parsedDate, setParsedDate] = useState('');
  const [isISODate, setIsISODate] = useState(false);
  const [validFormats, setValidFormats] = useState(supportedDateFormats);

  useEffect(() => {
    const uploadFile = importConfig.uploadFiles?.[0];
    if (!uploadFile) return;

    const getSampleForColumn = (columnType) => {
      if (!columnType) return '';

      const firstRows = uploadFile.firstRows;
      const columnTypes = uploadFile.parserConfig.metadata.columntypes;
      const columnName = Object.keys(columnTypes).find(key => columnTypes[key] === columnType);

      if (!columnName) return '';

      for (const row of firstRows) {
          if (row[columnName]?.length > 0) {
              return row[columnName];
          }

          const keys = Object.keys(row);
          const matchingKey = keys.find(key => {
              const cleanKey = key.replace(/[\"\\]/g, '').trim();
              return cleanKey === columnName;
          });

          if (matchingKey && row[matchingKey]) {
              return row[matchingKey];
          }
      }

      return '';
    };

    const datetime = getSampleForColumn('datetime');
    const temperature = getSampleForColumn('tempf');
    const illuminance = getSampleForColumn('vis');

    const isISO = dayjs(datetime).isValid() && datetime.includes('T');
    setIsISODate(isISO);

    setSampleData({
      datetime,
      temperature,
      illuminance
    });
  }, [importConfig.uploadFiles]);

  useEffect(() => {
    if (!importConfig.uploadFiles?.[0]) return;

    const newFormat = {
      date: isISODate ? null : dateFormat,
      timezone: isISODate ? null : timezone,
      temperature: tempUnit === 'celsius' ? 'C' : 'F',
      light: illumUnit
    };

    const hasRequiredFields = isISODate ?
      Boolean(newFormat.temperature) :
      Boolean(newFormat.date && newFormat.timezone);

    let isDateValid = true;
    if (!isISODate && sampleData.datetime && newFormat.date) {
      try {
        const parsedDate = dayjs(sampleData.datetime, newFormat.date);
        isDateValid = parsedDate.isValid();

        if (isDateValid) {
          console.log('DataFormattingStep - valid date format', sampleData.datetime, newFormat.date);
          setParsedDate(parsedDate.format(newFormat.date));
        } else {
          console.log('DataFormattingStep - invalid date format', sampleData.datetime, newFormat.date);
          setParsedDate(null);
        }
      } catch (error) {
        console.error('DataFormattingStep - error parsing date', error);
        isDateValid = false;
        setParsedDate(null);
      }
    }

    onUpdateImportConfig({
      ...importConfig,
      format: newFormat,
      formatResult: hasRequiredFields && isDateValid
    });
  }, [dateFormat, timezone, tempUnit, illumUnit, isISODate, sampleData.datetime]);

  useEffect(() => {
    const validFormats = getValidFormatsForDate(sampleData.datetime);
    setValidFormats(validFormats);

    if (validFormats.length > 0 && (!dateFormat || !validFormats.includes(dateFormat))) {
      setDateFormat(validFormats[0]);
    }
  }, [sampleData.datetime]);

  const handleChange = (field, value) => {
    switch (field) {
      case 'dateFormat':
        setDateFormat(value);
        break;
      case 'timezone':
        setTimezone(value);
        break;
      case 'temperatureUnit':
        setTempUnit(value);
        break;
      case 'illuminanceUnit':
        setIllumUnit(value);
        break;
    }
  };

  return (
    <Box p={3}>
      <Typography variant="h5" gutterBottom>
        Configure Data Format
      </Typography>
      <Typography variant="body2" color="textSecondary" paragraph>
        Please verify how your data will be interpreted before importing
      </Typography>

      <Box display="grid" gridGap={32} gridTemplateColumns="repeat(auto-fit, minmax(300px, 1fr))">
        <Box bgcolor="background.default" p={3} borderRadius={1} boxShadow={1}>
          <Typography variant="subtitle1" gutterBottom color="primary">
            Date & Time Configuration
          </Typography>

          <Box mb={2}>
            <Typography variant="caption" color="textSecondary">
              Sample from your data
            </Typography>
            <Typography variant="body1" gutterBottom fontWeight="medium">
              {sampleData.datetime || 'No date found'}
            </Typography>
          </Box>

          {parsedDate && (
            <Box mb={2}>
              <Typography variant="caption" color="textSecondary">
                Will be interpreted as
              </Typography>
              <Typography variant="body1" color="primary" fontWeight="medium">
                {getInterpretedDate(parsedDate, timezone)}
              </Typography>
            </Box>
          )}

          {isISODate ? (
            <Box mt={2} p={2} bgcolor="success.light" borderRadius={1}>
              <Typography variant="body2">
                ✓ ISO 8601 format detected - Your dates will be parsed automatically
              </Typography>
            </Box>
          ) : (
            <>
              <FormControl fullWidth variant="outlined" size="small" style={{ marginBottom: '1rem' }}>
                <InputLabel>Date Format</InputLabel>
                <Select
                  value={dateFormat || ''}
                  onChange={(e) => handleChange('dateFormat', e.target.value)}
                  label="Date Format"
                >
                  <MenuItem value="">
                    <em>Select a format</em>
                  </MenuItem>
                  {validFormats.map((format, index) => (
                    <MenuItem key={index} value={format}>
                      {format}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth variant="outlined" size="small">
                <InputLabel>Time Zone</InputLabel>
                <Select
                  value={timezone}
                  onChange={(e) => handleChange('timezone', e.target.value)}
                  label="Time Zone"
                >
                  {timezones.map((tz) => (
                    <MenuItem key={tz.name} value={tz.name}>
                      {getTimezoneLabel(tz)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )}
        </Box>

        {sampleData.temperature && (
          <Box bgcolor="background.default" p={3} borderRadius={1} boxShadow={1}>
            <Typography variant="subtitle1" gutterBottom color="primary">
              Temperature Configuration
            </Typography>

            <Box mb={2}>
              <Typography variant="caption" color="textSecondary">
                Sample from your data
              </Typography>
              <Typography variant="body1" gutterBottom fontWeight="medium">
                {sampleData.temperature}° {tempUnit === 'celsius' ? 'C' : 'F'}
              </Typography>
            </Box>

            <FormControl fullWidth variant="outlined" size="small">
              <InputLabel>Interpret Temperature As</InputLabel>
              <Select
                value={tempUnit}
                onChange={(e) => handleChange('temperatureUnit', e.target.value)}
                label="Interpret Temperature As"
              >
                {temperatureUnits.map((unit) => (
                  <MenuItem key={unit.value} value={unit.value}>
                    {unit.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )}

        {sampleData.illuminance && (
          <Box bgcolor="background.default" p={3} borderRadius={1} boxShadow={1}>
            <Typography variant="subtitle1" gutterBottom color="primary">
              Light Level Configuration
            </Typography>

            <Box mb={2}>
              <Typography variant="caption" color="textSecondary">
                Sample from your data
              </Typography>
              <Typography variant="body1" gutterBottom fontWeight="medium">
                {sampleData.illuminance} {illumUnit === 'lux' ? 'lux' : 'fc'}
              </Typography>
            </Box>

            <FormControl fullWidth variant="outlined" size="small">
              <InputLabel>Interpret Light Levels As</InputLabel>
              <Select
                value={illumUnit}
                onChange={(e) => handleChange('illuminanceUnit', e.target.value)}
                label="Interpret Light Levels As"
              >
                {illuminanceUnits.map((unit) => (
                  <MenuItem key={unit.value} value={unit.value}>
                    {unit.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default DataFormattingStep;
