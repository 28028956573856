import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Icon } from '@mdi/react';
import { useDrag, useDrop } from 'react-dnd';
import { importStyles, formValidationStyles } from '../../../style/styles';
import icons from '../../../style/icons';
import CancelCheck from '../../common/buttons/CancelCheck';

const useStyles = makeStyles({
	...importStyles,
	...formValidationStyles
});

function ConservFieldOption(props) {
	const classes = useStyles();
	const [, setIsDragging] = useState(false);

	const [, drag] = useDrag({
		item: {
			type: 'MAPFIELD',
			field: props.field
		},
		collect: monitor => {
			setIsDragging(monitor.isDragging());
		}
	});

	return (
		<Box ref={drag} classes={{ root: classes.draggableMapField }}>
			<Box classes={{ root: classes.importFieldTop }}>
				<Icon size={1} path={props.icon} />
				<Box display='flex' flexGrow={1} pl={1} pr={1} alignItems='center'>
					<Typography variant='body1'>{props.label}</Typography>
					{props.mappedTo ? (
						<>
							<Box pl={1} pr={1}>
								<Icon path={icons.action} size={1} />
							</Box>
							<Typography variant='body1'>{props.mappedTo}</Typography>
						</>
					) : null}
				</Box>
				<CancelCheck
					checked={props.mappedTo}
					onClick={() => props.onCancelMapping(props.mappedTo)}
				/>
				{/* <Icon size={1} path={icons.complete} color={props.mappedTo ? colors.blaze.primary : null}/> */}
			</Box>
		</Box>
	);
}

function UploadFieldOption(props) {
	const classes = useStyles();

	const [{ isOver }, drop] = useDrop({
		accept: 'MAPFIELD',
		drop: item => {
			props.onMapField(props.fileField, item.field);
		},
		collect: monitor => ({
			isOver: monitor.isOver()
		})
	});

	return (
		<Box
			ref={drop}
			classes={{ root: isOver ? classes.dropActiveField : classes.mapField }}
		>
			<Box classes={{ root: classes.importFieldTop }}>
				<Box display='flex' flexGrow={1} pl={1} pr={1} alignItems='center'>
					<Typography variant='body1'>{props.label}</Typography>
				</Box>
				{props.sampleData ? (
					<Box pr={1} display='flex' alignItems='center'>
						<Typography variant='body1'>{props.sampleData}</Typography>
					</Box>
				) : null}
				<CancelCheck
					checked={props.mapped}
					onClick={() => props.onCancelMapping(props.label)}
				/>
			</Box>
		</Box>
	);
}

function MapDataStep(props) {
	const classes = useStyles();
	const [mappedFields, setMappedFields] = useState({});
	const [mappingValidation, setMappingValidation] = useState(
		props.importConfig.mappingValidation || { valid: true }
	);

	useEffect(() => {
		const { parserConfig } = props.importConfig.uploadFiles[0];
		if (parserConfig && !props.importConfig.mappingValidation) {
			// for now, take the parser config for the first file, since we require all to be the same.
			const columnTypes = parserConfig.metadata.columntypes;
			const typeKeys = Object.keys(columnTypes);
			const newMappings = {};
			typeKeys.forEach(type => {
				if (columnTypes[type]) {
					newMappings[type] = columnTypes[type];
				}
			});
			setMappedFields(newMappings);
		}
	}, [props]);

	useEffect(() => {
		const mappingValidation = validateMapping();
		setMappingValidation(mappingValidation);
		const newImportConfig = { ...props.importConfig };
		newImportConfig.uploadFiles.forEach(uploadFile => {
			uploadFile.parserConfig.metadata.columntypes = mappedFields;
		});
		newImportConfig.mappingValidation = mappingValidation;
		props.onUpdateImportConfig(newImportConfig);
	}, [mappedFields]);

	const handleMapField = (fileField, field) => {
		const newMappings = { ...mappedFields };
		const keys = Object.keys(newMappings);
		// make sure to remove any old mappings, this needs to be 1:1
		keys.forEach(key => {
			if (newMappings[key] === field) delete newMappings[key];
		});
		newMappings[fileField] = field;
		setMappedFields(newMappings);
	};

	const validateMapping = () => {
		const mappingValidation = { valid: true };

		if (!findFieldMatch('datetime')) {
			mappingValidation.valid = false;
			mappingValidation.message = 'Date / time must be mapped';
		}
		if (
			!findFieldMatch('rh') &&
			!findFieldMatch('tempc') &&
			!findFieldMatch('tempf')
		) {
			mappingValidation.valid = false;
			mappingValidation.message =
				'At least one data field (temp or rh) must be mapped';
		}
		return mappingValidation;
	};

	const cancelMapping = fileField => {
		const newMappings = { ...mappedFields };
		delete newMappings[fileField];
		setMappedFields(newMappings);
	};

	const getSampleData = column => {
		let sampleData = '';
		const uploadFile = props.importConfig.uploadFiles[0];
		const firstRows = uploadFile.firstRows;
		
		// Special handling for Hanwell formats
		if (uploadFile.parserConfig.metadata.sourcedescription === 'Hanwell' || 
			uploadFile.parserConfig.metadata.sourcedescription === 'Hanwell hanlog32') {
			
			// Get only first 4 values
			const values = firstRows
				.slice(0, 4)  // Limit to first 4 rows
				.map(row => row[column])
				.filter(value => value && value.trim() !== '');  // Remove empty values

			// For temperature and humidity, format numbers to 1 decimal place
			if (column.includes('Temperature') || column.includes('Humidity')) {
				sampleData = values
					.map(v => parseFloat(v).toFixed(1))
					.join(', ');
			} else {
				// For date/time, just show the first value
				if (column === 'Time' || column === 'Date Time') {
					sampleData = values[0] || '';
				} else {
					sampleData = values.join(', ');
				}
			}
		} else {
			// Original logic for other formats
			firstRows.forEach((row, index) => {
				const value = row[column];
				if (value && value.length > 8 && !sampleData) {
					sampleData = value;
				} else if (value && value.length < 8 && value) {
					sampleData += value + (index < 3 ? ', ' : ''); // Limit to 4 values
				}
			});
		}
		
		return sampleData;
	};

	const createUploadFieldOptions = () => {
		const options = [];
		const { columns } = props.importConfig.uploadFiles[0].parserConfig.metadata;
		columns.forEach((column, index) => {
			options.push(
				<UploadFieldOption
					key={`${column}-${index}`}
					label={column}
					fileField={column}
					mapped={mappedFields[column]}
					onMapField={handleMapField}
					sampleData={getSampleData(column)}
					onCancelMapping={cancelMapping}
				/>
			);
		});
		return options;
	};

	const findFieldMatch = field => {
		const keys = Object.keys(mappedFields);
		let match = null;
		keys.forEach(key => {
			if (mappedFields[key] === field) match = key;
		});
		return match;
	};

	return (
		<Box pt={4}>
			<Box>
				<Typography variant='h3'>Map your fields</Typography>
				<Typography variant='body1'>
					Drag the fields from Conserv (left) to match with the fields from your
					file (right)
				</Typography>
			</Box>
			<Box pt={2} display='flex' flexDirection='row' maxHeight='400px'>
				<Box style={{ overflowY: 'auto' }}>
					{props.importConfig.uploadFiles.length === 1 ? (
						<ConservFieldOption
							label='Sensor'
							field='sensor'
							icon={icons.sensor}
							mappedTo={findFieldMatch('sensor')}
							onCancelMapping={cancelMapping}
						/>
					) : null}
					<ConservFieldOption
						label='Date / Time'
						field='datetime'
						icon={icons.time}
						mappedTo={findFieldMatch('datetime')}
						onCancelMapping={cancelMapping}
					/>
					<ConservFieldOption
						label='Temperature (°C)'
						field='tempc'
						icon={icons.temp}
						mappedTo={findFieldMatch('tempc')}
						onCancelMapping={cancelMapping}
					/>
					<ConservFieldOption
						label='Temperature (°F)'
						field='tempf'
						icon={icons.temp}
						mappedTo={findFieldMatch('tempf')}
						onCancelMapping={cancelMapping}
					/>
					<ConservFieldOption
						label='Relative Humidity'
						field='rh'
						icon={icons.rh}
						mappedTo={findFieldMatch('rh')}
						onCancelMapping={cancelMapping}
					/>
					<ConservFieldOption
						label='Illuminance'
						field='vis'
						icon={icons.illuminance}
						mappedTo={findFieldMatch('vis')}
						onCancelMapping={cancelMapping}
					/>
				</Box>
				<Box flexGrow={1} />
				<Box style={{ overflowY: 'auto' }}>{createUploadFieldOptions()}</Box>
			</Box>
			{!mappingValidation.valid ? (
				<Box
					mt={2}
					classes={{ root: classes.errorBox }}
					display='flex'
					flexDirection='row'
					justifyContent='center'
				>
					<Typography variant='body1'>{mappingValidation.message}</Typography>
				</Box>
			) : null}
		</Box>
	);
}

export default MapDataStep;
